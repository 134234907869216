import Vue from "vue";

/*TITLE*/
document.title = "DM 20 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "DM 20";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "DM 20";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-forest-maple-copia--20230112030103.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "dm20-banyo-principal-forest-maple-rodano-caliza-copia--20230112030113.jpg";
Vue.prototype.$image_bath2 = "dm20-banyo-auxiliar-forest-maple-rodano-caliza-mosaico-rodano-caliza-copia--20230112030121.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-forest-maple-copia--20230112030103.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "dm20-banyo-principal-forest-maple-rodano-caliza-copia--20230112030113.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "dm20-banyo-auxiliar-forest-maple-rodano-caliza-mosaico-rodano-caliza-copia--20230112030121.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20dr%20mallafré%20guasch%2015%20tarragona%2043005",
    text: "Carrer Dr. Mallafré Guasch, 15, Tarragona, 43005",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=tarragona%2043005",
    text: "Tarragona, 43005",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:977217950",
    text: "977217950",
  },
  {
    icon: "mdi-email",
    link: "mailto:ventas@promocionesjoseluis.com",
    text: "ventas@promocionesjoseluis.com",
  },
];
